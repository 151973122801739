import React, {useState, useEffect} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import dynamic from 'next/dynamic';

const ListingsListComponent = dynamic(() => import('../../../02_molecules/ListingsList'));

function InfiniteScrollPager(props) {
  const objHasher = require('object-hash')
  const {scrollData, gridCols, nodeType, perPage, scrollSettings} = props;
  const origData = objHasher(scrollData);

  if (!scrollData) {
    return null;
  }

  const [nodes, setNodes] = useState(scrollData.slice(0, perPage));
  const [origNodes, setOrigNodes] = useState(origData);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (origData != origNodes) {
      setOrigNodes(origData);
      setNodes(scrollData.slice(0, perPage));
      setHasMore(true);
    }
  });

  const showNodes = ((!nodes.length && scrollData.length) || (origNodes != origData)) ? scrollData.slice(0, perPage) : nodes;

  const getMoreNodes = () => {
    const showNodes = !nodes.length && scrollData.length ? scrollData.slice(0, perPage) : nodes;
    let offset = showNodes.length;
    const newNodes = !nodes.length && scrollData.length ? scrollData.slice(perPage, 2 * perPage) : scrollData.slice(offset, offset + perPage);
    setNodes([...showNodes, ...newNodes]);
    if (showNodes.length >= scrollData.length) {
      setHasMore(false);
    }
  };

  let pagerDefault = {
    height: 590,
    style: {overflowX: "hidden", minWidth: '100%'},
    loader: <h3>Loading...</h3>,
    dataLength: showNodes.length,
    next: getMoreNodes,
    hasMore: hasMore,
    // children: ReactNode;
    // scrollThreshold?: number | string;
    // endMessage?: ReactNode;
    // style?: CSSProperties;
    // scrollableTarget?: ReactNode;
    // hasChildren?: boolean;
    // inverse?: boolean;
    // pullDownToRefresh?: boolean;
    // pullDownToRefreshContent?: ReactNode;
    // releaseToRefreshContent?: ReactNode;
    // pullDownToRefreshThreshold?: number;
    // refreshFunction?: Fn;
    // onScroll?: (e: MouseEvent) => any;
    // dataLength: number;
    // initialScrollY?: number;
    // className?: string;
  }
  pagerDefault = scrollSettings ? {...pagerDefault, ...scrollSettings} : pagerDefault;

  return (
    <InfiniteScroll {...pagerDefault}>
      {nodeType == 'listing' && (
        <ListingsListComponent
          listings={showNodes}
          colMd={gridCols}
        />
      )}
      {nodeType != 'listing' &&
      showNodes.map((dataNode) => {
        return (
          <h3>{dataNode.title}</h3>
        );
      })
      }

    </InfiniteScroll>
  );
}

// export default withRouter(InfiniteScrollPager);
export default InfiniteScrollPager;

import request from '../utils/request';
import {listingDrupalTransform, listingSolr, contentNodesSolr} from '../utils/transforms';

/**
 * Returns single listings from the backend by uuid.
 *
 * @param id
 *   Drupal UUID of the listing.
 *
 * @returns {Promise<any>}
 */
export const getFullListingInfo = id => new Promise((resolve, reject) => {
  request('api')
      .get(`/listings/${id}`)
      .query({
        'include': 'slides,slides.imageFile,image,image.imageFile,user_ref,user_ref.image.imageFile,city_term,city_term.slides.imageFile,comm_term,comm_term.slides.imageFile,neighborhood,neighborhood.slides.imageFile' +
          ',elemSchool,highSchool,midSchool',
        // 'fields[listings]': 'id,internalId,path,title,body,image,slides,mlsNum,saleType,saleStatus,bathFull,bathHalf,bathAll,beds,coord,garage_space,price,dateAvail,sqft,lotSize',
        'fields[users]': 'internalId,id,mail,roles,public_email,name,full_name,image,image.imageFile,address,subtitle,agentType',
        'fields[images]': 'imageFile',
        'fields[imagesMls]': 'imageFile',
        'fields[files]': 'uri',
      })
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      .ok(resp => resp.statusCode)
      .then((response) => {
        resolve({
          page_data: response.statusCode === 200 ? listingDrupalTransform(response.body.data) : {},
          statusCode: response.statusCode,
        });
      })
      .catch((error) => {
        console.error('Could not fetch the listing.', error);
        reject(error);
      });
});

/**
 * Returns listings search resultset from Solr.
 *
 * @param pageLimit
 *   Listings per page.
 *
 * @param pageOffset
 *   Amount of listings to skip from the beginning (for pagination).
 *
 * @returns {Promise<any>}
 */
export const listingsSolrSearch = async (url, pageLimit, pageOffset, q) => new Promise((resolve, reject) => {
  let query = 'wt=json&';

  url = url || '/solr';
  pageLimit = pageLimit || 24;
  pageOffset = pageOffset || 0;
  q = q || 'q=*:*&fl=*';

  query += q + '&rows=' + pageLimit + '&start=' + pageOffset;
  request('solr', true)
    // Via POST (safer, as the GET query might hit length limit):
      .post('/select')
      .send(query)
        // Tell superagent to consider any valid Drupal response as successful.
      .ok((resp) => {
        return resp.statusCode;
      })
      .then((response) => {
        let new_data = (q.includes('fq=ss_content_type:listing')) ? response.body.response.docs.map(node => listingSolr(node)) : response.body.response.docs.map(node => contentNodesSolr(node));
        resolve({
          page_data: response.statusCode === 200 && response.body.response?.numFound > 0 ? new_data : [],
          ttlResults: response.statusCode === 200 ? response.body.response?.numFound : false,
          ttlPages: (response.statusCode === 200 && response.body.response?.numFound) ? Math.ceil(response.body.response?.numFound / pageLimit) : false,
          hasNextPage: response.statusCode === 200 && response.body?.response?.numFound && response.body?.response?.docs?.length > response.body.response.numFound,
          // hasPrevPage: !!response.body.links.prev,
          statusCode: response.statusCode,
          initial: false,
        });
      })
      .catch((error) => {
        console.error('Could not fetch list of listings.', error);
        reject(error);
      });
});

/**
 * Solr More Like This search query.
 *
 * @param pageLimit
 *   Listings per page.
 *
 * @param pageOffset
 *   Amount of listings to skip from the beginning (for pagination).
 *
 * @returns {Promise<any>}
 */
export const listingsSolrMLTSearch = async (url, pageLimit, pageOffset, q) => new Promise((resolve, reject) => {
  let query = 'wt=json&omitHeader=true&json.nl=flat&mlt.mintf=1&mlt.mindf=1&mlt.interestingTerms=none&mlt.match.include=false&mlt.match.offset=0';
  // https://public-master.solrcluster.com/solr/eaton_live/mlt?wt=json&omitHeader=true&json.nl=flat&mlt.mintf=1&mlt.mindf=1&mlt.interestingTerms=none&mlt.match.include=false&mlt.match.offset=0&mlt.fl=tm_X3b_en_city&q=id:%225cwkwn-eat_listings-entity:node/639:en%22

  url = url || '/solr';
  pageLimit = pageLimit || 24;
  pageOffset = pageOffset || 0;
  q = q || '';

  query += q + '&rows=' + pageLimit + '&start=' + pageOffset;
  request('solr', true)
      .get('/mlt')
      .query(query)
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      //   .ok(resp => resp.statusCode)
      .ok((resp) => {
        return resp.statusCode;
      })
      .then((response) => {
        resolve({
          listings: response.statusCode === 200 && response.body.response?.numFound > 0 ? response.body.response.docs.map(listing => listingSolr(listing)) : [],
          ttlResults: response.statusCode === 200 ? response.body.response?.numFound : false,
          ttlPages: response.statusCode === 200 && response.body.response?.numFound ? Math.ceil(response.body.response?.numFound / pageLimit) : false,
          hasNextPage: response.statusCode === 200 && response.body?.response?.numFound && response.body?.response?.docs?.length > response.body.response.numFound,
          // hasPrevPage: !!response.body.links.prev,
          statusCode: response.statusCode,
          initial: false,
        });
      })
      .catch((error) => {
        console.error('listingsSolrMLTSearch: Could not fetch list of listings.', error);
        reject(error);
      });
});

// https://github.com/vercel/next.js/tree/canary/examples/with-static-export
import {useEffect, useReducer, useState} from 'react';
import dynamic from "next/dynamic";
import useSWR from 'swr';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListingSearchContext
  from '../../components/context/ListingSearchContext';
import NoResultsMessage from '../../components/01_atoms/NoResultsMessage';
import * as listingApi from '../../api/listing';
import CustomMuiButton from '../../components/01_atoms/CustomMuiButton';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Spinner from '../../components/01_atoms/Spinner';
import Error from '../../components/01_atoms/ErrorMessage';
import InfiniteScrollPager
  from "../../components/01_atoms/Pagination/InfiniteScrollPager";
import GlobalFooter
  from '../../components/03_organisms/GlobalFooter/Footer';
import {getSolrClient} from '../../utils/solr';
import {fetchAPIRequestData} from "../../api/global";

const DynamicSearchForm = dynamic(() => import('../../components/01_atoms/ListingSearchForm'), {ssr: false});
const MapWithAMarkerClusterer = dynamic(() => import('../../components/01_atoms/Maps/MapClusters'), {ssr: false});

const useStyles = makeStyles((theme) => ({
  root: {
    '& .outer-map-wrapper': {
      width: 'calc(100vw - 650px)',
      [theme.breakpoints.down("md")]: {
        width: 'calc(100vw - 325px)',

      },
      [theme.breakpoints.down("sm")]: {
        width: '100%',
      }
    },
    '& .mapListingWrapper': {
      width: '650px',
      [theme.breakpoints.down("md")]: {
        width: '325px',
        '& .MuiGrid-spacing-xs-1 > .MuiGrid-item': {
          maxWidth: '100%',
          flexBasis: '100%',
        },

      },
      [theme.breakpoints.down("sm")]: {
        width: '100%',
      },
    },
  },
}));

/**
 * Amount of listings to display per page.
 */
const LISTINGS_PER_PAGE = 24;
const LISTINGS_SOLR_LIMIT = 500;
export const resetDefaultValues = {
  txt: '',
  type: 'Rental',
  prop_type: [0],
  price_min: 0,
  price_max: 10000,
  sqft_min: 0,
  sqft_max: 6000,
  city: ['all'],
  neighbor: ['all'],
  beds: 0,
  baths: 0,
  // garage_min: 0,
  // garage_max: 5,
  pool: false,
  waterfront: false,
  featured: false,
  available: false,
  openhouse: false,
  sort: [{dir: 'DESC', field: 'its_eaton'}],
  // page: 0,
};

let defaultValues = {
  // txt: '',
  type: 'Rental',
  // prop_type: [0],
  price_min: 0,
  price_max: 10000,
  sqft_min: 0,
  sqft_max: 6000,
  city: ['all'],
  neighbor: ['all'],
  // beds: 0,
  // baths: 0,
  // garage_min: 0,
  // garage_max: 5,
  // pool: false,
  // waterfront: false,
  // featured: false,
  // available: false,
  sort: [{dir: 'DESC', field: 'its_eaton'}],
  // page: 0,
};

export async function getServerSideProps(context) {
  let initialProps = {
    // page: null,
    listings: [],
    lookup: false,
    initial: true,
    query: context.query,
    // hasNextPage: false,
    // hasPrevPage: false,
    statusCode: 200,
  };
  let search_city, search_neighbor;

  try {
    // Get list of listings from Drupal backend.
    [search_city, search_neighbor] = await Promise.all([
      fetchAPIRequestData(`/api-admin/rest/view/city-terms`, {}, {type: 'tld'}),
      fetchAPIRequestData(`/api-admin/rest/view/neighbor-terms`, {}, {type: 'tld'})
    ]);
  }
  catch (e) {
    console.warn('Homes for Rent Error', e);

    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side rendering we want the server to throw the
    // correct error code.
    // if (res) res.statusCode = 500;
  }

  // If certain page does not contain listings, we throw "Page not found"
  // header. It's needed for the right SEO.
  // if (!initialProps.listings.length && currentPage > 0) {
  // Pass status code as internal properly. It is being checked inside of
  // render() method of _app.js.
  // initialProps.statusCode = 404;

  // In case of Server Side rendering we want the server to throw the
  // correct error code.
  // if (res) res.statusCode = 404;
  // }

  return {
    props: {
      ...initialProps,
      cityFilterOpts: (search_city?.page_data || []),
      neighborFilterOpts: (search_neighbor?.page_data || [])
    }, // will be passed to the page component as props
  }
}

function HomesForRentPage(props) {
  let swr_req = {}, solr_q = '', newSettOverride = {};

  let query_obj = {
    solr_fields: 'score,its_nid,id,ss_url,ss_uuid,ss_img_uri,ss_sale_type,its_price,its_bed,its_bath_all,tm_X3b_en_title,tm_X3b_en_addr1,tm_X3b_en_addr2,tm_X3b_en_city,tm_X3b_en_state,ss_zip,fts_lat,fts_lon'
      + ',its_brokerage,its_eaton,sm_prop_type,its_sale_status,its_market_days,ds_date_avail,fts_lot_acres,its_sqft',
    type: 'Rental',
    sort: [{dir: 'DESC', field: 'its_eaton'}],
  };

  const solrClient = getSolrClient();
  if (props.query) {
    // Parse search params from URL query:
    newSettOverride = solrClient.parseUrlQuery(props.query);
    query_obj = {...query_obj, ...newSettOverride};

    // Merge vals to pass to the search form for rendering:
    newSettOverride = {...defaultValues, ...newSettOverride};
  }

  solr_q = solrClient.assembleQuery(query_obj);
  const solrRequestDefault = {
    query: solr_q,
    page_data: [],
    map_bbox: '',
    map_zoom: 11,
    map_center: {lat: 27.964, lng: -82.400},
    curr_page: 0,
    curr_offset: 0,
    is_submitting: false,
    initial: true,
    ttlResults: false,
    ttlPages: false,
  };

  const [values, setValues] = useState(solrRequestDefault);

  function ListingSearchContextReducer(state, form_item) {
    const combine = {/*...values,*/ ...state, ...form_item};
    return combine;
  }

  const [solrReq, setSolrReq] = useReducer(ListingSearchContextReducer, values);
  const solr_query = solrReq.map_bbox && solrReq.map_bbox != '' ? solrReq.query + solrReq.map_bbox : solrReq.query;
  swr_req = useSWR((solrReq.initial || solrReq.is_submitting) ? ['/solr', LISTINGS_SOLR_LIMIT, solrReq.curr_page * LISTINGS_SOLR_LIMIT, solr_query] : null, listingApi.listingsSolrSearch, {
    // initialData: initData,
    // revalidateOnMount: true,
  });
  const {data, error} = swr_req;

  // --------------   @see https://swr.vercel.app/examples/infinite-loading
  let listings = solrReq && solrReq.page_data ? solrReq.page_data : []; // FOR
                                                                        // INFINITE
                                                                        // LOADING
  // const isLoadingInitialData = !data && !error;
  const isSubmitted = data || error;
  // const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof
  // data[size - 1] === "undefined");
  const isEmpty = solrReq?.ttlResults === 0;
  // const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length <
  // PAGE_SIZE); const isRefreshing = isValidating && data && data.length ===
  // size;
  const initData = {
    initial: true,
    statusCode: 200,
  };

  if (isSubmitted) {
    let fresh_data = {listings: []};
    fresh_data = {...fresh_data, initial: false};
    if (!data?.page_data) {
      fresh_data = {
        ...fresh_data,
        listings: [],
        ttlResults: data?.ttlResults,
        ttlPages: 0
      };
      listings = [];
    }
    if (data?.page_data) {
      fresh_data = {
        ...fresh_data,
        page_data: data.page_data,
        ttlResults: data?.ttlResults,
        ttlPages: data?.ttlPages
      };
      listings = data.page_data;
    }
    if (solrReq.is_submitting) {
      fresh_data = {...fresh_data, is_submitting: false};
    }
    setSolrReq({...solrReq, ...fresh_data});
  }

  useEffect(() => {
    document.body.classList.remove('not-search-body');
  })
  const noResultsMessage = (
    <Box p={4}>
      <Typography variant="h4" component="h2"><strong>No results found</strong></Typography>
      <Typography>Please reset the filters to continue searching.</Typography>
      <CustomMuiButton href="/rent/homes-for-rent" color="primary"
                       size="large" label={"Reset"}
                       stronglabel={"Search"}></CustomMuiButton>
    </Box>
  );
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className="alignfull listingWrapper">
          <ListingSearchContext.Provider value={{solrReq, setSolrReq}}>
            {(error) &&
            <Error/>
            }
            <Grid container spacing={3}>
              <Grid item xs>
                <DynamicSearchForm type={'Rental'}
                                   cities={props?.cityFilterOpts}
                                   neighbors={props?.neighborFilterOpts}
                                   settOverride={newSettOverride}/>
              </Grid>
            </Grid>

            {(solrReq.is_submitting || solrReq.initial) &&
            <Spinner/>
            }
            <h1 className="sr-only">Eaton Realty Homes for Rent</h1>
            <Grid container spacing={0}>
              <Grid className={`outer-map-wrapper`} item>
                <Hidden smDown>
                <div className={`map-wrapper`}>

                  <div
                    className={`info-msg`}>Showing {solrReq.ttlResults < 501 ? solrReq.ttlResults : `500 out of ${solrReq.ttlResults}`} properties
                    in this area.<br/>Zoom the map to view more.
                  </div>
                  <MapWithAMarkerClusterer markers={listings}/>
                  <div className="legend-color">
                    <div className="item multiple">Multiple Properties</div>
                    <div className="item p-normal">Property for Rent</div>
                    <div className="item p-featured">Featured Property for
                      Rent
                    </div>
                  </div>
                </div>
                </Hidden>
              </Grid>
              <Grid item className="mapListingWrapper">
                <Box>
                  {solrReq?.page_data?.length > 0 && !isEmpty &&
                  <>
                    <Box px={1}>
                      <InfiniteScrollPager scrollData={listings} gridCols={6}
                                           nodeType={`listing`} layout={`card`}
                                           perPage={12}>
                      </InfiniteScrollPager>
                      <br/>
                      <small>Property information is provided exclusively for
                        consumers&apos; personal, non-commercial use and may not
                        be
                        used for any purpose other than to identify prospective
                        properties consumers
                        may be interested in purchasing. Data is deemed reliable
                        but
                        is not guaranteed. Data courtesy Stellar MLS as
                        of {new Date().toUTCString()}</small>
                      <br/>
                    </Box>

                  </>
                  }
                  {(!solrReq.ttlResults || !solrReq.ttlPages) &&
                  <NoResultsMessage message={noResultsMessage}/>
                  }
                  <Box mt={3}>
                    <GlobalFooter/>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </ListingSearchContext.Provider>
        </div>
      </div>
    </>
  );
}

export default HomesForRentPage;
